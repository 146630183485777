import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Organisation, OrganisationInsertDto, Request } from '@exe/client/client-web/core/supabase';
import { ApiResponsePaginated } from '../store.model';

const prefix = '[Organisations]';

export const clearOrganisation = createAction(`${prefix} Clear organisation`);
export const fetchOrganisation = createAction(`${prefix} Fetch organisation`, props<{ payload: Request }>());
export const fetchOrganisationSuccess = createAction(`${prefix} Fetch organisation success`, props<{ payload: ApiResponsePaginated<Organisation> }>());
export const fetchOrganisationFailure = createAction(`${prefix} Fetch organisation failure`, props<{ payload: HttpErrorResponse }>());

export const createOrganisation = createAction(`${prefix} Create organisation`, props<{ payload: OrganisationInsertDto }>());
export const createOrganisationSuccess = createAction(`${prefix} Create organisation success`);
export const createOrganisationFailure = createAction(`${prefix} Create organisation failure`, props<{ payload: HttpErrorResponse }>())
