import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  Project,
  ProjectDeleteDto,
  ProjectInsertDto,
  ProjectUpdateDto,
  Request
} from '@exe/client/client-web/core/supabase';
import { ApiResponsePaginated } from '../store.model';

const prefix = '[Projects]';

export const clearProjects = createAction(`${prefix} Clear projects`);
export const fetchProjects = createAction(`${prefix} Fetch projects`, props<{ payload: Request }>());
export const fetchProjectsSuccess = createAction(`${prefix} Fetch projects success`, props<{ payload: ApiResponsePaginated<Project> }>());
export const fetchProjectsFailure = createAction(`${prefix} Fetch projects failure`, props<{ payload: HttpErrorResponse }>());

export const addProject = createAction(`${prefix} Add project`, props<{ payload: ProjectInsertDto }>());
export const addProjectSuccess = createAction(`${prefix} Add project success`);
export const addProjectFailure = createAction(`${prefix} Add project failure`, props<{ payload: HttpErrorResponse }>());

export const updateProject = createAction(`${prefix} Update project`, props<{ payload: ProjectUpdateDto }>());
export const updateProjectSuccess = createAction(`${prefix} Update project success`);
export const updateProjectFailure = createAction(`${prefix} Update project failure`, props<{ payload: HttpErrorResponse }>());

export const deleteProjects = createAction(`${prefix} Delete projects`, props<{ payload: ProjectDeleteDto }>());
export const deleteProjectsSuccess = createAction(`${prefix} Delete projects success`);
export const deleteProjectsFailure = createAction(`${prefix} Delete projects failure`, props<{ payload: HttpErrorResponse }>());
