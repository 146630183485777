import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { productsReducer } from './products.reducers';
import { ProductsEffects } from '.';

@NgModule({
  imports: [StoreModule.forFeature('products', productsReducer), EffectsModule.forFeature([ProductsEffects])]
})
export class ProductsModule {}
