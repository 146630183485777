import { createReducer, on, Action } from '@ngrx/store';
import { ApiResponsePaginated, ApiResponseState, initialApiResponseState } from '../store.model';
import { Product } from '@exe/client/client-web/core/supabase';
import * as fromProducts from './index';

export interface ProductsState {
  products: ApiResponseState<ApiResponsePaginated<Product>>;
}

export const initialProductsState: ProductsState = {
  products: initialApiResponseState
};

const reducer = createReducer(
  initialProductsState,
  on(fromProducts.clearProducts, (state) => {
    return {
      ...state,
      products: initialProductsState.products
    };
  }),
  on(fromProducts.fetchProducts, (state) => {
    return {
      ...state,
      products: {
        ...state.products,
        isLoading: true,
        isLoaded: false
      }
    };
  }),
  on(fromProducts.fetchProductsSuccess, (state, { payload }) => {
    return {
      ...state,
      products: {
        ...state.products,
        isLoading: false,
        isLoaded: true,
        data: payload
      }
    };
  }),
  on(fromProducts.fetchProductsFailure, (state, { payload }) => {
    return {
      ...state,
      products: {
        ...state.products,
        isLoading: false,
        isLoaded: false,
        errors: [...state.products.errors, payload]
      }
    };
  }),
  on(fromProducts.deleteProducts, (state) => {
    return {
      ...state,
      products: {
        ...state.products,
        isLoading: true,
        isLoaded: false
      }
    };
  }),
  on(fromProducts.deleteProductsSuccess, (state) => {
    return {
      ...state,
      products: {
        ...state.products,
        isLoading: false,
        isLoaded: true
      }
    };
  }),
  on(fromProducts.deleteProductsFailure, (state, { payload }) => {
    return {
      ...state,
      products: {
        ...state.products,
        isLoading: false,
        isLoaded: false,
        errors: [...state.products.errors, payload]
      }
    };
  })
);

export function productsReducer(state = initialProductsState, actions: Action) {
  return reducer(state, actions);
}
