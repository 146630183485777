import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  Action,
  ActionDeleteDto,
  ActionInsertDto,
  ActionUpdateDto,
  Request
} from '@exe/client/client-web/core/supabase';
import { ApiResponsePaginated } from '../store.model';

const prefix = '[Actions]';

export const clearActions = createAction(`${prefix} Clear actions`);
export const fetchActions = createAction(`${prefix} Fetch actions`, props<{ payload: Request }>());
export const fetchActionsSuccess = createAction(`${prefix} Fetch actions success`, props<{ payload: ApiResponsePaginated<Action> }>());
export const fetchActionsFailure = createAction(`${prefix} Fetch actions failure`, props<{ payload: HttpErrorResponse }>());

export const addAction = createAction(`${prefix} Add action`, props<{ payload: ActionInsertDto }>());
export const addActionSuccess = createAction(`${prefix} Add action success`);
export const addActionFailure = createAction(`${prefix} Add action failure`, props<{ payload: HttpErrorResponse }>());

export const updateAction = createAction(`${prefix} Update action`, props<{ payload: ActionUpdateDto }>());
export const updateActionSuccess = createAction(`${prefix} Update action success`);
export const updateActionFailure = createAction(`${prefix} Update action failure`, props<{ payload: HttpErrorResponse }>());

export const deleteActions = createAction(`${prefix} Delete actions`, props<{ payload: ActionDeleteDto }>());
export const deleteActionsSuccess = createAction(`${prefix} Delete actions success`);
export const deleteActionsFailure = createAction(`${prefix} Delete actions failure`, props<{ payload: HttpErrorResponse }>());
