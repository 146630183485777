import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ActionEvent, ActionEventInsertDto, Request } from '@exe/client/client-web/core/supabase';
import { ApiResponsePaginated } from '../store.model';

const prefix = '[ActionEvents]';

export const clearActionEvents = createAction(`${prefix} Clear action events`);
export const fetchActionEvents = createAction(`${prefix} Fetch action events`, props<{ payload: Request }>());
export const fetchActionEventsSuccess = createAction(`${prefix} Fetch action events success`, props<{ payload: ApiResponsePaginated<ActionEvent> }>());
export const fetchActionEventsFailure = createAction(`${prefix} Fetch action events failure`, props<{ payload: HttpErrorResponse }>());

export const addActionEvent = createAction(`${prefix} Add action event`, props<{ payload: ActionEventInsertDto }>());
export const addActionEventSuccess = createAction(`${prefix} Add action event success`);
export const addActionEventFailure = createAction(`${prefix} Add action event failure`, props<{ payload: HttpErrorResponse }>());

export const clearLastActionEvents = createAction(`${prefix} Clear last action events`);
export const fetchLastActionEvents = createAction(`${prefix} Fetch last action events`, props<{ payload: Request }>());
export const fetchLastActionEventsSuccess = createAction(`${prefix} Fetch last action events success`, props<{ payload: ApiResponsePaginated<ActionEvent> }>());
export const fetchLastActionEventsFailure = createAction(`${prefix} Fetch last action events failure`, props<{ payload: HttpErrorResponse }>());

