import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import * as fromUsers from './users.actions';
import {
  selectLoggedInUser, selectLoggedInUserErrors, selectLoggedInUserIsLoaded, selectLoggedInUserIsLoading,
  selectUsers,
  selectUsersErrors,
  selectUsersIsLoaded,
  selectUsersIsLoading,
  selectUsersTotalCount
} from './users.selectors';
import { ApiResponsePaginated } from '../store.model';
import { Request, User, UserInsertDto } from '@exe/client/client-web/core/supabase';


@Injectable({
  providedIn: 'root'
})
export class UsersFacadeService {
  constructor(private readonly store: Store) {}

  clearUsers(): void {
    return this.store.dispatch(fromUsers.clearUsers());
  }

  fetchUsers(usersGetDto: Request): void {
    return this.store.dispatch(fromUsers.fetchUsers({ payload: usersGetDto }));
  }

  isUsersLoading$(): Observable<boolean> {
    return this.store.select(selectUsersIsLoading).pipe(
      filter(usersIsLoading => !!usersIsLoading)
    );
  }

  isUsersLoaded$(): Observable<boolean> {
    return this.store.select(selectUsersIsLoaded).pipe(
      filter(usersIsLoaded => !!usersIsLoaded)
    );
  }

  getUsers$(): Observable<ApiResponsePaginated<User>> {
    return this.store.select(selectUsers).pipe(
      filter(users => !!users)
    );
  }

  getUsersErrors$(): Observable<HttpErrorResponse[]> {
    return this.store.select(selectUsersErrors).pipe(
      filter(errors => !!errors)
    );
  }

  getUsersTotalCount$(): Observable<number> {
    return this.store.select(selectUsersTotalCount).pipe(
      filter(usersTotalCount => !!usersTotalCount)
    );
  }

  clearLoggedInUser(): void {
    return this.store.dispatch(fromUsers.clearLoggedInUser());
  }

  fetchLoggedInUser(loggedInUserGetDto: string): void {
    return this.store.dispatch(fromUsers.fetchLoggedInUser({ payload: loggedInUserGetDto }));
  }

  isLoggedInUserLoading$(): Observable<boolean> {
    return this.store.select(selectLoggedInUserIsLoading).pipe(
      filter(loggedInUserIsLoading => !!loggedInUserIsLoading)
    );
  }

  isLoggedInUserLoaded$(): Observable<boolean> {
    return this.store.select(selectLoggedInUserIsLoaded).pipe(
      filter(loggedInUserIsLoaded => !!loggedInUserIsLoaded)
    );
  }

  getLoggedInUser$(): Observable<User> {
    return this.store.select(selectLoggedInUser)
  }

  getLoggedInUserErrors$(): Observable<HttpErrorResponse[]> {
    return this.store.select(selectLoggedInUserErrors).pipe(
      filter(errors => !!errors)
    );
  }

  addUser(createUserRequestDto: UserInsertDto): void {
    return this.store.dispatch(fromUsers.addUser({ payload: createUserRequestDto }));
  }
}
