import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, first, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import * as fromProjects from './projects.actions';
import {
  selectProjects, selectProjectsErrors,
  selectProjectsIsLoaded,
  selectProjectsIsLoading, selectProjectsTotalCount

} from './projects.selectors';
import { ApiResponsePaginated } from '../store.model';
import {
  Project,
  ProjectDeleteDto,
  ProjectInsertDto,
  ProjectUpdateDto,
  Request
} from '@exe/client/client-web/core/supabase';
import { Actions, ofType } from '@ngrx/effects';

@Injectable({
  providedIn: 'root'
})
export class ProjectsFacadeService {
  constructor(private readonly store: Store, private actions$: Actions) {}

  clearProjects(): void {
    return this.store.dispatch(fromProjects.clearProjects());
  }

  fetchProjects(getProjectsRequestDto: Request): void {
    return this.store.dispatch(fromProjects.fetchProjects({ payload: getProjectsRequestDto }));
  }

  isProjectsLoading$(): Observable<boolean> {
    return this.store.select(selectProjectsIsLoading).pipe(
      filter(projectsIsLoading => !!projectsIsLoading)
    );
  }

  isProjectsLoaded$(): Observable<boolean> {
    return this.store.select(selectProjectsIsLoaded).pipe(
      filter(projectsIsLoaded => !!projectsIsLoaded)
    );
  }

  getProjects$(): Observable<ApiResponsePaginated<Project>> {
    return this.store.select(selectProjects).pipe(
      filter(projects => !!projects)
    );
  }

  getProjectsErrors$(): Observable<HttpErrorResponse[]> {
    return this.store.select(selectProjectsErrors).pipe(
      filter(errors => !!errors)
    );
  }

  getProjectsTotalCount$(): Observable<number> {
    return this.store.select(selectProjectsTotalCount).pipe(
      filter(projectsTotalCount => !!projectsTotalCount)
    );
  }

  addProject(createProjectRequestDto: ProjectInsertDto): Observable<void> {
    this.store.dispatch(fromProjects.addProject({ payload: createProjectRequestDto }));

    return this.actions$.pipe(
      ofType(fromProjects.addProjectSuccess),
      first()
    ) as Observable<void>;
  }

  updateProject(updateProjectRequestDto: ProjectUpdateDto): Observable<void> {
    this.store.dispatch(fromProjects.updateProject({ payload: updateProjectRequestDto }));

    return this.actions$.pipe(
      ofType(fromProjects.updateProjectSuccess),
      first()
    ) as Observable<void>;
  }

  deleteProjects(projectDeleteDto: ProjectDeleteDto): Observable<void> {
    this.store.dispatch(fromProjects.deleteProjects({ payload: projectDeleteDto }));

    return this.actions$.pipe(
      ofType(fromProjects.deleteProjectsSuccess),
      first()
    ) as Observable<void>;
  }
}
