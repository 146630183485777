import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { map, Observable, switchMap } from 'rxjs';
import { UsersFacadeService } from '@exe/client/client-web/core/store';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard {
  constructor(
    private usersFacadeService: UsersFacadeService
  ) {}

  canMatch(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const requiredRoles: string[] = route.data['requiredRoles'] as string[];
    return this.usersFacadeService.isLoggedInUserLoaded$().pipe(
      switchMap(() => this.usersFacadeService.getLoggedInUser$()),
      map(loggedInUser => requiredRoles.some(role => loggedInUser.role === role))
    )
  }
}
