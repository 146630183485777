import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  Order,
  OrderDeleteDto,
  OrderInsertDto,
  OrderUpdateDto,
  Request
} from '@exe/client/client-web/core/supabase';
import { ApiResponsePaginated } from '../store.model';

const prefix = '[Orders]';

export const clearOrders = createAction(`${prefix} Clear orders`);
export const fetchOrders = createAction(`${prefix} Fetch orders`, props<{ payload: Request }>());
export const fetchOrdersSuccess = createAction(`${prefix} Fetch orders success`, props<{ payload: ApiResponsePaginated<Order> }>());
export const fetchOrdersFailure = createAction(`${prefix} Fetch orders failure`, props<{ payload: HttpErrorResponse }>());

export const addOrder = createAction(`${prefix} Add orders`, props<{ payload: OrderInsertDto }>());
export const addOrderSuccess = createAction(`${prefix} Add orders success`);
export const addOrderFailure = createAction(`${prefix} Add orders failure`, props<{ payload: HttpErrorResponse }>());

export const updateOrder = createAction(`${prefix} Update order`, props<{ payload: OrderUpdateDto }>());
export const updateOrderSuccess = createAction(`${prefix} Update order success`);
export const updateOrderFailure = createAction(`${prefix} Update order failure`, props<{ payload: HttpErrorResponse }>());

export const deleteOrders = createAction(`${prefix} Delete orders`, props<{ payload: OrderDeleteDto }>());
export const deleteOrdersSuccess = createAction(`${prefix} Delete orders success`);
export const deleteOrdersFailure = createAction(`${prefix} Delete orders failure`, props<{ payload: HttpErrorResponse }>());
