import { createReducer, on, Action } from '@ngrx/store';
import { ApiResponsePaginated, ApiResponseState, initialApiResponseState } from '../store.model';
import { Project } from '@exe/client/client-web/core/supabase';
import * as fromProjects from './index';

export interface ProjectsState {
  projects: ApiResponseState<ApiResponsePaginated<Project>>;
}

export const initialProjectsState: ProjectsState = {
  projects: initialApiResponseState
};

const reducer = createReducer(
  initialProjectsState,
  on(fromProjects.clearProjects, (state) => {
    return {
      ...state,
      projects: initialProjectsState.projects
    };
  }),
  on(fromProjects.fetchProjects, (state) => {
    return {
      ...state,
      projects: {
        ...state.projects,
        isLoading: true,
        isLoaded: false
      }
    };
  }),
  on(fromProjects.fetchProjectsSuccess, (state, { payload }) => {
    return {
      ...state,
      projects: {
        ...state.projects,
        isLoading: false,
        isLoaded: true,
        data: payload
      }
    };
  }),
  on(fromProjects.fetchProjectsFailure, (state, { payload }) => {
    return {
      ...state,
      projects: {
        ...state.projects,
        isLoading: false,
        isLoaded: false,
        errors: [...state.projects.errors, payload]
      }
    };
  }),
  on(fromProjects.updateProject, (state) => {
    return {
      ...state,
      projects: {
        ...state.projects,
        isLoading: true,
        isLoaded: false
      }
    };
  }),
  on(fromProjects.updateProjectSuccess, (state) => {
    return {
      ...state,
      projects: {
        ...state.projects,
        isLoading: false,
        isLoaded: true
      }
    };
  }),
  on(fromProjects.updateProjectFailure, (state, { payload }) => {
    return {
      ...state,
      projects: {
        ...state.projects,
        isLoading: false,
        isLoaded: false,
        errors: [...state.projects.errors, payload]
      }
    };
  })
);

export function projectsReducer(state = initialProjectsState, actions: Action) {
  return reducer(state, actions);
}
