import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductsState } from './products.reducers';

export const selectProductsState = createFeatureSelector<ProductsState>('products');

export const selectProducts = createSelector(selectProductsState, (state) => state.products.data);
export const selectProductsIsLoading = createSelector(selectProductsState, (state) => state.products.isLoading);
export const selectProductsIsLoaded = createSelector(selectProductsState, (state) => state.products.isLoaded);
export const selectProductsErrors = createSelector(selectProductsState, (state) => state.products.errors);
export const selectProductsTotalCount = createSelector(selectProductsState, (state) => state.products.data?.totalCount);
