import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { SupabaseService } from '@exe/client/client-web/core/supabase';
import * as fromActions from './index';

@Injectable()
export class ActionsEffects {
  constructor(
    private readonly actions$: Actions,
    private supabaseService: SupabaseService
  ) {}

  getActions = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.fetchActions),
      switchMap(({ payload }) => {
        return from(this.supabaseService.buildSelectQuery(payload));
      }),
      map(({data , count}) => fromActions.fetchActionsSuccess({
        payload: {
          array: data!,
          totalCount: count!
        }
      })),
      catchError((error) => of(fromActions.fetchActionsFailure({ payload: error })))
    )
  );

  addAction = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addAction),
      switchMap(({ payload }) => {
        return from(this.supabaseService.client.from('actions').insert(payload));
      }),
      map(() => fromActions.addActionSuccess()),
      catchError((error) => of(fromActions.addActionFailure({ payload: error })))
    )
  );

  updateAction = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateAction),
      switchMap(({ payload }) => {
        return from(this.supabaseService.client.from('actions').update(payload).eq('id', payload.id));
      }),
      map(() => fromActions.updateActionSuccess()),
      catchError((error) => of(fromActions.updateActionFailure({ payload: error })))
    )
  );

  deleteActions = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteActions),
      switchMap(({ payload }) => {
        return from(this.supabaseService.client.from('actions')
          .delete()
          .in('id', payload.map(action => action.id))
        );
      }),
      map(() => fromActions.deleteActionsSuccess()),
      catchError((error) => of(fromActions.deleteActionsFailure({ payload: error })))
    )
  );
}
