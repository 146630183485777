import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { SupabaseService } from '@exe/client/client-web/core/supabase';
import * as fromCustomers from './index';

@Injectable()
export class CustomersEffects {
  constructor(
    private readonly actions$: Actions,
    private supabaseService: SupabaseService
  ) {}

  getCustomers = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCustomers.fetchCustomers),
      switchMap(({ payload }) => {
        return from(this.supabaseService.buildSelectQuery(payload));
      }),
      map(({data , count}) => fromCustomers.fetchCustomersSuccess({
        payload: {
          array: data!,
          totalCount: count!
        }
      })),
      catchError((error) => of(fromCustomers.fetchCustomersFailure({ payload: error })))
    )
  );

  addCustomer = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCustomers.addCustomer),
      switchMap(({ payload }) => {
        return from(this.supabaseService.client.from('customers').insert(payload));
      }),
      map(() => fromCustomers.addCustomerSuccess()),
      catchError((error) => of(fromCustomers.addCustomerFailure({ payload: error })))
    )
  );

  updateCustomer = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCustomers.updateCustomer),
      switchMap(({ payload }) => {
        return from(this.supabaseService.client.from('customers').update(payload).eq('id', payload.id));
      }),
      map(() => fromCustomers.updateCustomerSuccess()),
      catchError((error) => of(fromCustomers.updateCustomerFailure({ payload: error })))
    )
  );

  deleteCustomers = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCustomers.deleteCustomers),
      switchMap(({ payload }) => {
        return from(this.supabaseService.client.from('customers')
          .delete()
          .in('id', payload.map(action => action.id))
        );
      }),
      map(() => fromCustomers.deleteCustomersSuccess()),
      catchError((error) => of(fromCustomers.deleteCustomersFailure({ payload: error })))
    )
  );
}
