import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgrxStoreModule } from '@exe/client/client-web/core/store';
import { HttpErrorsHandlerModule } from '@exe/client/client-web/core/http-errors-handler';
import { TranslocoRootModule } from '@exe/client/client-web/core/transloco';
import { SocketIOModule } from '@exe/client/shared/socket-io';
import { FirebaseModule } from '@exe-trace/client/client-web/core/firebase';
import { SupabaseModule } from '../../../supabase/src/lib/supabase.module';
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgrxStoreModule,
    HttpErrorsHandlerModule,
    TranslocoRootModule,
    SocketIOModule,
    FirebaseModule,
    SupabaseModule
  ],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgrxStoreModule,
    HttpErrorsHandlerModule,
    TranslocoRootModule,
    SocketIOModule,
    FirebaseModule,
    SupabaseModule
  ],
  providers: [
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { timezone: 'GMT+4' }
    }
  ]
})
export class CoreModule {}
