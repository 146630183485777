import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { actionEventsReducer } from './action-events.reducers';
import { ActionEventsEffects } from './action-events.effects';

@NgModule({
  imports: [StoreModule.forFeature('action-events', actionEventsReducer), EffectsModule.forFeature([ActionEventsEffects])]
})
export class ActionEventsModule {}
