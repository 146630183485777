export interface FileInfo {
  name: string,
  path: string
}

export enum UPLOAD_DOWNLOAD_ACTIONS {
  UPLOAD_START = 'UPLOAD_START',
  UPLOAD_COMPLETE = 'UPLOAD_COMPLETE',
  DOWNLOAD_STARTED = 'DOWNLOAD_START',
  DOWNLOAD_COMPLETE = 'DOWNLOAD_COMPLETE'
}
