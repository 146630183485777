import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, first, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import * as fromActionEvents from './action-events.actions';
import {
  selectActionEvents, selectActionEventsErrors,
  selectActionEventsIsLoaded,
  selectActionEventsIsLoading, selectActionEventsTotalCount

} from './action-events.selectors';
import { ApiResponsePaginated } from '../store.model';
import {
  ActionEvent,
  ActionEventInsertDto,
  Request,
  Tables
} from '@exe/client/client-web/core/supabase';
import { Actions, ofType } from '@ngrx/effects';

@Injectable({
  providedIn: 'root'
})
export class ActionEventsFacadeService {
  constructor(private readonly store: Store, private actions$: Actions) {}

  clearActionEvents(): void {
    return this.store.dispatch(fromActionEvents.clearActionEvents());
  }

  fetchActionEvents(getActionEventsRequestDto: Request): void {
    getActionEventsRequestDto = {
      ...getActionEventsRequestDto,
      select: `*, action:${Tables.ACTIONS}(*), product:${Tables.PRODUCTS}!products_lastActionEventId_fkey(*), user:${Tables.USERS}(*)`
    }
    return this.store.dispatch(fromActionEvents.fetchActionEvents({ payload: getActionEventsRequestDto }));
  }

  isActionEventsLoading$(): Observable<boolean> {
    return this.store.select(selectActionEventsIsLoading).pipe(
      filter(actionEventsIsLoading => !!actionEventsIsLoading)
    );
  }

  isActionEventsLoaded$(): Observable<boolean> {
    return this.store.select(selectActionEventsIsLoaded).pipe(
      filter(actionEventsIsLoaded => !!actionEventsIsLoaded)
    );
  }

  getActionEvents$(): Observable<ApiResponsePaginated<ActionEvent>> {
    return this.store.select(selectActionEvents).pipe(
      filter(actionEvents => !!actionEvents)
    );
  }

  getActionEventsErrors$(): Observable<HttpErrorResponse[]> {
    return this.store.select(selectActionEventsErrors).pipe(
      filter(errors => !!errors)
    );
  }

  getActionEventsTotalCount$(): Observable<number> {
    return this.store.select(selectActionEventsTotalCount).pipe(
      filter(actionEventsTotalCount => !!actionEventsTotalCount)
    );
  }

  addActionEvent(actionEventInsertDto: ActionEventInsertDto): Observable<void> {
    this.store.dispatch(fromActionEvents.addActionEvent({ payload: actionEventInsertDto }));

    return this.actions$.pipe(
      ofType(fromActionEvents.addActionEventSuccess),
      first()
    ) as Observable<void>;
  }

  getLastActionEvents(): Observable<ActionEvent[]> {
    const getLastActionEventsRequestDto = {
      select: `*, action:${Tables.ACTIONS}(*), product:${Tables.PRODUCTS}!products_lastActionEventId_fkey(*), user:${Tables.USERS}(*)`
    }
    this.store.dispatch(fromActionEvents.fetchLastActionEvents({ payload: getLastActionEventsRequestDto }));

    return this.actions$.pipe(
      ofType(fromActionEvents.fetchLastActionEventsSuccess),
      first()
    ) as Observable<ActionEvent[]>;
  }

}
