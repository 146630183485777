import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { filter, forkJoin, map, merge, Observable, of, switchMap } from 'rxjs';
import { FileInfo, UPLOAD_DOWNLOAD_ACTIONS } from './firebase.model';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private basePath = '/tenants/execode/drawings/';

  constructor(private fireStorage: AngularFireStorage) { }

  uploadFiles(files: File[]): Observable<FileInfo | string> {
    const tasks: AngularFireUploadTask[] = [];
    const filesInfo: Observable<FileInfo>[] = [];

    files.forEach(file => {
      const filePath = `${this.basePath}${file.name}`;
      const task = this.fireStorage.upload(filePath, file);
      tasks.push(task);

      const fileInfo$ = task.snapshotChanges().pipe(
        filter(snapshot => snapshot!.state === 'success'),
        map(snapshot => ({
          name: file.name,
          path: snapshot!.ref.fullPath
        }))
      );
      filesInfo.push(fileInfo$);
    });

    return merge(
      of(UPLOAD_DOWNLOAD_ACTIONS.UPLOAD_START),
      ...filesInfo, forkJoin(filesInfo).pipe(
        switchMap(() => of(UPLOAD_DOWNLOAD_ACTIONS.UPLOAD_COMPLETE))
      )
    );
  }

  getDownloadUrl(path: string): Observable<string> {
    const fileRef: AngularFireStorageReference = this.fireStorage.ref(path);
    return fileRef.getDownloadURL();
  }

}
