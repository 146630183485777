import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionEventsState } from './action-events.reducers';

export const selectActionEventsState = createFeatureSelector<ActionEventsState>('action-events');

export const selectActionEvents = createSelector(selectActionEventsState, (state) => state.actionEvents.data);
export const selectActionEventsIsLoading = createSelector(selectActionEventsState, (state) => state.actionEvents.isLoading);
export const selectActionEventsIsLoaded = createSelector(selectActionEventsState, (state) => state.actionEvents.isLoaded);
export const selectActionEventsErrors = createSelector(selectActionEventsState, (state) => state.actionEvents.errors);
export const selectActionEventsTotalCount = createSelector(selectActionEventsState, (state) => state.actionEvents.data?.totalCount);
