import { createReducer, on, Action } from '@ngrx/store';
import { ApiResponsePaginated, ApiResponseState, initialApiResponseState } from '../store.model';
import { Customer } from '@exe/client/client-web/core/supabase';
import * as fromCustomers from './index';

export interface CustomersState {
  customers: ApiResponseState<ApiResponsePaginated<Customer>>;
}

export const initialCustomersState: CustomersState = {
  customers: initialApiResponseState
};

const reducer = createReducer(
  initialCustomersState,
  on(fromCustomers.clearCustomers, (state) => {
    return {
      ...state,
      customers: initialCustomersState.customers
    };
  }),
  on(fromCustomers.fetchCustomers, (state) => {
    return {
      ...state,
      customers: {
        ...state.customers,
        isLoading: true,
        isLoaded: false
      }
    };
  }),
  on(fromCustomers.fetchCustomersSuccess, (state, { payload }) => {
    return {
      ...state,
      customers: {
        ...state.customers,
        isLoading: false,
        isLoaded: true,
        data: payload
      }
    };
  }),
  on(fromCustomers.fetchCustomersFailure, (state, { payload }) => {
    return {
      ...state,
      customers: {
        ...state.customers,
        isLoading: false,
        isLoaded: false,
        errors: [...state.customers.errors, payload]
      }
    };
  })
);

export function customersReducer(state = initialCustomersState, actions: Action) {
  return reducer(state, actions);
}
