import { createReducer, on, Action } from '@ngrx/store';
import { ApiResponsePaginated, ApiResponseState, initialApiResponseState } from '../store.model';
import * as fromOrders from './index';
import { Order } from '@exe/client/client-web/core/supabase';

export interface OrdersState {
  orders: ApiResponseState<ApiResponsePaginated<Order>>;
}

export const initialOrdersState: OrdersState = {
  orders: initialApiResponseState
};

const reducer = createReducer(
  initialOrdersState,
  on(fromOrders.clearOrders, (state) => {
    return {
      ...state,
      orders: initialOrdersState.orders
    };
  }),
  on(fromOrders.fetchOrders, (state) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        isLoading: true,
        isLoaded: false
      }
    };
  }),
  on(fromOrders.fetchOrdersSuccess, (state, { payload }) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        isLoading: false,
        isLoaded: true,
        data: payload
      }
    };
  }),
  on(fromOrders.fetchOrdersFailure, (state, { payload }) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        isLoading: false,
        isLoaded: false,
        errors: [...state.orders.errors, payload]
      }
    };
  }),
  on(fromOrders.updateOrder, (state) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        isLoading: true,
        isLoaded: false
      }
    };
  }),
  on(fromOrders.updateOrderSuccess, (state) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        isLoading: false,
        isLoaded: true
      }
    };
  }),
  on(fromOrders.updateOrderFailure, (state, { payload }) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        isLoading: false,
        isLoaded: false,
        errors: [...state.orders.errors, payload]
      }
    };
  }),
  on(fromOrders.deleteOrders, (state) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        isLoading: true,
        isLoaded: false
      }
    };
  }),
  on(fromOrders.deleteOrdersSuccess, (state) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        isLoading: false,
        isLoaded: true
      }
    };
  }),
  on(fromOrders.deleteOrdersFailure, (state, { payload }) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        isLoading: false,
        isLoaded: false,
        errors: [...state.orders.errors, payload]
      }
    };
  })
);

export function ordersReducer(state = initialOrdersState, actions: Action) {
  return reducer(state, actions);
}
