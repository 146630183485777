import { HttpErrorResponse } from "@angular/common/http";

export interface ApiResponsePaginated<T> {
  array: T[];
  totalCount: number;
}

export interface ApiResponseState<T> {
  data: T;
  isLoading: boolean;
  isLoaded: boolean;
  errors: HttpErrorResponse[];
}

// eslint-disable-next-line
export const initialApiResponseState: ApiResponseState<any> = {
  data: null,
  isLoading: false,
  isLoaded: false,
  errors: []
};
