import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { SupabaseService } from '@exe/client/client-web/core/supabase';
import * as fromOrders from './index';

@Injectable()
export class OrdersEffects {
  constructor(
    private readonly actions$: Actions,
    private supabaseService: SupabaseService
  ) {}

  getOrders = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrders.fetchOrders),
      switchMap(({ payload }) => {
        return from(this.supabaseService.buildSelectQuery(payload));
      }),
      map(({data , count}) => fromOrders.fetchOrdersSuccess({
        payload: {
          array: data!,
          totalCount: count!
        }
      })),
      catchError((error) => of(fromOrders.fetchOrdersFailure({ payload: error })))
    )
  );

  addOrder = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrders.addOrder),
      switchMap(({ payload }) => {
        return from(this.supabaseService.client.from('orders').insert(payload))
      }),
      map(() => fromOrders.addOrderSuccess()),
      catchError((error) => of(fromOrders.addOrderFailure({ payload: error })))
    )
  );

  updateOrder = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrders.updateOrder),
      switchMap(({ payload }) => {
        console.log(payload)
        return from(this.supabaseService.client.from('orders').update(payload).eq('id', payload.id))
      }),
      map(() => fromOrders.updateOrderSuccess()),
      catchError((error) => of(fromOrders.updateOrderFailure({ payload: error })))
    )
  );

  deleteOrders = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrders.deleteOrders),
      switchMap(({ payload }) => {
        return from(this.supabaseService.client.from('orders')
          .delete()
          .in('id', payload.map(order => order.id))
        );
      }),
      map(() => fromOrders.deleteOrdersSuccess()),
      catchError((error) => of(fromOrders.deleteOrdersFailure({ payload: error })))
    )
  );
}
