import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectsState } from './projects.reducers';

export const selectProjectsState = createFeatureSelector<ProjectsState>('projects');

export const selectProjects = createSelector(selectProjectsState, (state) => state.projects.data);
export const selectProjectsIsLoading = createSelector(selectProjectsState, (state) => state.projects.isLoading);
export const selectProjectsIsLoaded = createSelector(selectProjectsState, (state) => state.projects.isLoaded);
export const selectProjectsErrors = createSelector(selectProjectsState, (state) => state.projects.errors);
export const selectProjectsTotalCount = createSelector(selectProjectsState, (state) => state.projects.data?.totalCount);
