/* istanbul ignore file */

import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { URL_TREE } from '@exe/client/client-web/shared/navigation';
import { LoginGuard } from '@exe/client/client-web/shared/guards';


export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: URL_TREE.DASHBOARD.URL,
    pathMatch: 'full',
  },
  {
    path: URL_TREE.AUTH.URL,
    loadChildren: () => import('@exe-trace/client/client-web/feature/feature-auth').then((m) => m.FeatureAuthModule),
  },
  {
    path: URL_TREE.DASHBOARD.URL,
    loadChildren: () => import('@exe/client/client-web/feature/feature-dashboard').then((m) => m.FeatureDashboardModule),
    canMatch: [LoginGuard]
  },
  {
    path: URL_TREE.USER_CHECK.URL,
    loadChildren: () => import('@exe-trace/client/client-web/shared/user-check').then((m) => m.UserCheckModule)
  },
  {
    path: URL_TREE.INIT_ORGANISATION.URL,
    loadChildren: () => import('@exe-trace/client/client-web/shared/init-organisation').then((m) => m.InitOrganisationModule)
  },
  {
    path: URL_TREE.NOT_AUTHORIZED.URL,
    loadChildren: () => import('@exe/client/client-web/shared/not-authorized').then((m) => m.NotAuthorizedModule)
  },
  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () => import('@exe/client/client-web/shared/not-found').then((m) => m.NotFoundModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
