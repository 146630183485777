import { Action, createReducer, on } from '@ngrx/store';
import { ApiResponsePaginated, ApiResponseState, initialApiResponseState } from '../store.model';
import { Action as ActionType } from '@exe/client/client-web/core/supabase';
import * as fromActions from './index';

export interface ActionsState {
  actions: ApiResponseState<ApiResponsePaginated<ActionType>>;
}

export const initialActionsState: ActionsState = {
  actions: initialApiResponseState
};

const reducer = createReducer(
  initialActionsState,
  on(fromActions.clearActions, (state) => {
    return {
      ...state,
      actions: initialActionsState.actions
    };
  }),
  on(fromActions.fetchActions, (state) => {
    return {
      ...state,
      actions: {
        ...state.actions,
        isLoading: true,
        isLoaded: false
      }
    };
  }),
  on(fromActions.fetchActionsSuccess, (state, { payload }) => {
    return {
      ...state,
      actions: {
        ...state.actions,
        isLoading: false,
        isLoaded: true,
        data: payload
      }
    };
  }),
  on(fromActions.fetchActionsFailure, (state, { payload }) => {
    return {
      ...state,
      actions: {
        ...state.actions,
        isLoading: false,
        isLoaded: false,
        errors: [...state.actions.errors, payload]
      }
    };
  }),
  on(fromActions.updateAction, (state) => {
    return {
      ...state,
      actions: {
        ...state.actions,
        isLoading: true,
        isLoaded: false
      }
    };
  }),
  on(fromActions.updateActionSuccess, (state) => {
    return {
      ...state,
      actions: {
        ...state.actions,
        isLoading: false,
        isLoaded: true
      }
    };
  }),
  on(fromActions.updateActionFailure, (state, { payload }) => {
    return {
      ...state,
      actions: {
        ...state.actions,
        isLoading: false,
        isLoaded: false,
        errors: [...state.actions.errors, payload]
      }
    };
  }),
  on(fromActions.deleteActions, (state) => {
    return {
      ...state,
      actions: {
        ...state.actions,
        isLoading: true,
        isLoaded: false
      }
    };
  }),
  on(fromActions.deleteActionsSuccess, (state) => {
    return {
      ...state,
      actions: {
        ...state.actions,
        isLoading: false,
        isLoaded: true
      }
    };
  }),
  on(fromActions.deleteActionsFailure, (state, { payload }) => {
    return {
      ...state,
      actions: {
        ...state.actions,
        isLoading: false,
        isLoaded: false,
        errors: [...state.actions.errors, payload]
      }
    };
  })
);

export function actionsReducer(state = initialActionsState, actions: Action) {
  return reducer(state, actions);
}
