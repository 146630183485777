import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { SupabaseService } from '@exe/client/client-web/core/supabase';
import * as fromProducts from './index';

@Injectable()
export class ProductsEffects {
  constructor(
    private readonly actions$: Actions,
    private supabaseService: SupabaseService
  ) {}

  getProducts = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProducts.fetchProducts),
      switchMap(({ payload }) => {
        return from(this.supabaseService.buildSelectQuery(payload));
      }),
      map(({data , count}) => fromProducts.fetchProductsSuccess({
        payload: {
          array: data!,
          totalCount: count!
        }
      })),
      catchError((error) => of(fromProducts.fetchProductsFailure({ payload: error })))
    )
  );

  addProduct = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProducts.addProduct),
      switchMap(({ payload }) => {
        return from(this.supabaseService.client.from('products').insert(payload))
      }),
      map(() => fromProducts.addProductSuccess()),
      catchError((error) => of(fromProducts.addProductFailure({ payload: error })))
    )
  );

  deleteProducts = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProducts.deleteProducts),
      switchMap(({ payload }) => {
        return from(this.supabaseService.client.from('products')
          .delete()
          .in('id', payload.map(action => action.id))
        );
      }),
      map(() => fromProducts.deleteProductsSuccess()),
      catchError((error) => of(fromProducts.deleteProductsFailure({ payload: error })))
    )
  );
}
