import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Request, User, UserInsertDto } from '@exe/client/client-web/core/supabase';
import { ApiResponsePaginated } from '../store.model';


const prefix = '[Users]';

export const clearUsers = createAction(`${prefix} Clear users`);
export const fetchUsers = createAction(`${prefix} Fetch users`, props<{ payload: Request }>());
export const fetchUsersSuccess = createAction(`${prefix} Fetch users success`, props<{ payload: ApiResponsePaginated<User> }>());
export const fetchUsersFailure = createAction(`${prefix} Fetch users failure`, props<{ payload: HttpErrorResponse }>());

export const clearLoggedInUser = createAction(`${prefix} Clear logged in user`);
export const fetchLoggedInUser = createAction(`${prefix} Fetch logged in user`, props<{ payload: string }>());
export const fetchLoggedInUserSuccess = createAction(`${prefix} Fetch logged in user success`, props<{ payload: User }>());
export const fetchLoggedInUserFailure = createAction(`${prefix} Fetch logged in user failure`, props<{ payload: HttpErrorResponse }>());

export const addUser = createAction(`${prefix} Add user`, props<{ payload: UserInsertDto }>());
export const addUserSuccess = createAction(`${prefix} Add user success`);
