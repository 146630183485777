import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, first, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import * as fromCustomers from './customers.actions';
import {
  selectCustomers,
  selectCustomersErrors,
  selectCustomersIsLoaded,
  selectCustomersIsLoading,
  selectCustomersTotalCount
} from './customers.selectors';
import { ApiResponsePaginated } from '../store.model';
import {
  Customer,
  CustomerDeleteDto,
  CustomerInsertDto,
  CustomerUpdateDto,
  Request
} from '@exe/client/client-web/core/supabase';
import { Actions, ofType } from '@ngrx/effects';

@Injectable({
  providedIn: 'root'
})
export class CustomersFacadeService {
  constructor(private readonly store: Store, private actions$: Actions) {}

  clearCustomers(): void {
    return this.store.dispatch(fromCustomers.clearCustomers());
  }

  fetchCustomers(customerGetDto: Request): void {
    return this.store.dispatch(fromCustomers.fetchCustomers({ payload: customerGetDto }));
  }

  isCustomersLoading$(): Observable<boolean> {
    return this.store.select(selectCustomersIsLoading).pipe(
      filter(customersIsLoading => !!customersIsLoading)
    );
  }

  isActionsLoaded$(): Observable<boolean> {
    return this.store.select(selectCustomersIsLoaded).pipe(
      filter(customersIsLoaded => !!customersIsLoaded)
    );
  }

  getCustomers$(): Observable<ApiResponsePaginated<Customer>> {
    return this.store.select(selectCustomers).pipe(
      filter(customers => !!customers)
    );
  }

  getCustomersErrors$(): Observable<HttpErrorResponse[]> {
    return this.store.select(selectCustomersErrors).pipe(
      filter(errors => !!errors)
    );
  }

  getCustomersTotalCount$(): Observable<number> {
    return this.store.select(selectCustomersTotalCount).pipe(
      filter(customersTotalCount => !!customersTotalCount)
    );
  }

  addCustomer(customerCreateDto: CustomerInsertDto): Observable<void> {
    this.store.dispatch(fromCustomers.addCustomer({ payload: customerCreateDto }));

    return this.actions$.pipe(
      ofType(fromCustomers.addCustomerSuccess),
      first()
    ) as Observable<void>
  }

  updateCustomer(customerUpdateDto: CustomerUpdateDto): Observable<void> {
    this.store.dispatch(fromCustomers.updateCustomer({ payload: customerUpdateDto }));

    return this.actions$.pipe(
      ofType(fromCustomers.updateCustomerSuccess),
      first()
    ) as Observable<void>
  }

  deleteCustomers(customerDeleteDto: CustomerDeleteDto): Observable<void> {
    this.store.dispatch(fromCustomers.deleteCustomers({ payload: customerDeleteDto }));

    return this.actions$.pipe(
      ofType(fromCustomers.deleteCustomersSuccess),
      first()
    ) as Observable<void>
  }
}
