import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ordersReducer } from './orders.reducers';
import { OrdersEffects } from '.';

@NgModule({
  imports: [StoreModule.forFeature('orders', ordersReducer), EffectsModule.forFeature([OrdersEffects])]
})
export class OrdersModule {}
