import { NgModule } from '@angular/core';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire/compat';

@NgModule({
  imports: [
    AngularFireModule.initializeApp({
      apiKey: "AIzaSyA2M9UJrUpS5sNV-Z6bbPQSPqZ1bEmfAg4",
      authDomain: "exetrace.firebaseapp.com",
      projectId: "exetrace",
      storageBucket: "exetrace.appspot.com",
      messagingSenderId: "740841440946",
      appId: "1:740841440946:web:77869db57bde507a2debab",
      measurementId: "G-XB4JRFK1LH"
    }),
    provideFirebaseApp(() => initializeApp()),
    provideFirestore(() => getFirestore()),
  ],
})
export class FirebaseModule {}
