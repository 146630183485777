import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { actionsReducer } from './actions.reducers';
import { ActionsEffects } from '.';

@NgModule({
  imports: [StoreModule.forFeature('actions', actionsReducer), EffectsModule.forFeature([ActionsEffects])]
})
export class ActionsModule {}
