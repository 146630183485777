import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { projectsReducer } from './projects.reducers';
import { ProjectsEffects } from './projects.effects';

@NgModule({
  imports: [StoreModule.forFeature('projects', projectsReducer), EffectsModule.forFeature([ProjectsEffects])]
})
export class ProjectsModule {}
