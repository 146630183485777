import { Injectable } from '@angular/core';
import { SupabaseService } from '@exe/client/client-web/core/supabase';
import { NavigationService } from '@exe/client/client-web/shared/navigation';
import { UsersFacadeService } from '@exe/client/client-web/core/store';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard {
  constructor(
    private supabaseService: SupabaseService,
    private navigationService: NavigationService,
    private usersFacadeService: UsersFacadeService
  ) {}

  async canMatch(): Promise<boolean> {
    const { data: { session } } = await this.supabaseService.client.auth.getSession();

    if (!session) {
      this.navigationService.navigateToLogin()
      return false;
    }
    const loggedInUser = await firstValueFrom(this.usersFacadeService.getLoggedInUser$());

    if (!loggedInUser) {
      this.usersFacadeService.fetchLoggedInUser(session.user.id)
    }

    return true;
  }
}
