import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { organisationsReducer } from './organisations.reducers';
import { OrganisationsEffects } from '.';

@NgModule({
  imports: [StoreModule.forFeature('organisations', organisationsReducer), EffectsModule.forFeature([OrganisationsEffects])]
})
export class OrganisationsModule {}
