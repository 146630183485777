import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { SupabaseService } from '@exe/client/client-web/core/supabase';
import * as fromProjects from './index';

@Injectable()
export class ProjectsEffects {
  constructor(
    private readonly actions$: Actions,
    private supabaseService: SupabaseService
  ) {}

  getProjects = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjects.fetchProjects),
      switchMap(({ payload }) => {
        return from(this.supabaseService.buildSelectQuery(payload));
      }),
      map(({data , count}) => fromProjects.fetchProjectsSuccess({
        payload: {
          array: data!,
          totalCount: count!
        }
      })),
      catchError((error) => of(fromProjects.fetchProjectsFailure({ payload: error })))
    )
  );

  addProject = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjects.addProject),
      switchMap(({ payload }) => {
        return from(this.supabaseService.client.from('projects').insert(payload));
      }),
      map(() => fromProjects.addProjectSuccess()),
      catchError((error) => of(fromProjects.addProjectFailure({ payload: error })))
    )
  );

  updateProject = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjects.updateProject),
      switchMap(({ payload }) => {
        return from(this.supabaseService.client.from('projects').update(payload).eq('id', payload.id));
      }),
      map(() => fromProjects.updateProjectSuccess()),
      catchError((error) => of(fromProjects.updateProjectFailure({ payload: error })))
    )
  );

  deleteProjects = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjects.deleteProjects),
      switchMap(({ payload }) => {
        return from(this.supabaseService.client.from('projects')
          .delete()
          .in('id', payload.map(action => action.id))
        );
      }),
      map(() => fromProjects.deleteProjectsSuccess()),
      catchError((error) => of(fromProjects.deleteProjectsFailure({ payload: error })))
    )
  );
}
