import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { customersReducer } from './customers.reducers';
import { CustomersEffects } from './customers.effects';

@NgModule({
  imports: [StoreModule.forFeature('customers', customersReducer), EffectsModule.forFeature([CustomersEffects])]
})
export class CustomersModule {}
