import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionsState } from './actions.reducers';

export const selectActionsState = createFeatureSelector<ActionsState>('actions');

export const selectActions = createSelector(selectActionsState, (state) => state.actions.data);
export const selectActionsIsLoading = createSelector(selectActionsState, (state) => state.actions.isLoading);
export const selectActionsIsLoaded = createSelector(selectActionsState, (state) => state.actions.isLoaded);
export const selectActionsErrors = createSelector(selectActionsState, (state) => state.actions.errors);
export const selectActionsTotalCount = createSelector(selectActionsState, (state) => state.actions.data?.totalCount);
