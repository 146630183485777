import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Observable } from 'rxjs';
import * as fromOrganisations from './organisations.actions';
import {
  selectOrganisation,
  selectOrganisationIsLoaded,
  selectOrganisationIsLoading
} from './organisations.selectors';
import { ApiResponsePaginated } from '../store.model';
import { Request, Organisation, OrganisationInsertDto } from '@exe/client/client-web/core/supabase';

@Injectable({
  providedIn: 'root'
})
export class OrganisationsFacadeService {
  constructor(private readonly store: Store) {}

  clearOrganisation(): void {
    return this.store.dispatch(fromOrganisations.clearOrganisation());
  }

  fetchOrganisation(organisationsGetDto: Request): void {
    return this.store.dispatch(fromOrganisations.fetchOrganisation({ payload: organisationsGetDto }));
  }

  isOrganisationLoading$(): Observable<boolean> {
    return this.store.select(selectOrganisationIsLoading).pipe(
      filter(actionsIsLoading => !!actionsIsLoading)
    );
  }

  isOrganisationLoaded$(): Observable<boolean> {
    return this.store.select(selectOrganisationIsLoaded).pipe(
      filter(organisationIsLoaded => !!organisationIsLoaded)
    );
  }

  getOrganisation$(): Observable<ApiResponsePaginated<Organisation>> {
    return this.store.select(selectOrganisation).pipe(
      filter(organisation => !!organisation)
    );
  }

  createOrganisation(organisationsCreateDto: OrganisationInsertDto): void {
    return this.store.dispatch(fromOrganisations.createOrganisation({ payload: organisationsCreateDto }));
  }
}
