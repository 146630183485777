import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { ActionsModule } from './actions/actions.module';
import { UsersModule } from './users/users.module';
import { OrganisationsModule } from './organisations/organisation.module';
import { CustomersModule } from './customers/customers.module';
import { ProjectsModule } from './projects/projects.module';
import { OrdersModule } from './orders/orders.module';
import { ActionEventsModule } from './action-events/action-events.module';
import { ProductsModule } from './products/products.module';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({}),
    ActionsModule,
    UsersModule,
    OrganisationsModule,
    CustomersModule,
    ProjectsModule,
    OrdersModule,
    ProductsModule,
    ActionEventsModule
  ]
})
export class NgrxStoreModule {}
