import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, first, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import * as fromActions from './actions.actions';
import {
  selectActions,
  selectActionsErrors,
  selectActionsIsLoaded,
  selectActionsIsLoading,
  selectActionsTotalCount
} from './actions.selectors';
import {
  Action as ActionType,
  ActionDeleteDto,
  ActionInsertDto,
  ActionUpdateDto,
  Request
} from '@exe/client/client-web/core/supabase';
import { ApiResponsePaginated } from '../store.model';
import { Actions, ofType } from '@ngrx/effects';

@Injectable({
  providedIn: 'root'
})
export class ActionsFacadeService {
  constructor(private readonly store: Store, private actions$: Actions) {}

  clearActions(): void {
    return this.store.dispatch(fromActions.clearActions());
  }

  fetchActions(actionsGetDto: Request): void {
    return this.store.dispatch(fromActions.fetchActions({ payload: actionsGetDto }));
  }

  isActionsLoading$(): Observable<boolean> {
    return this.store.select(selectActionsIsLoading).pipe(
      filter(actionsIsLoading => !!actionsIsLoading)
    );
  }

  isActionsLoaded$(): Observable<boolean> {
    return this.store.select(selectActionsIsLoaded).pipe(
      filter(actionsIsLoaded => !!actionsIsLoaded)
    );
  }

  getActions$(): Observable<ApiResponsePaginated<ActionType>> {
    return this.store.select(selectActions).pipe(
      filter(actions => !!actions)
    );
  }

  getActionsErrors$(): Observable<HttpErrorResponse[]> {
    return this.store.select(selectActionsErrors).pipe(
      filter(errors => !!errors)
    );
  }

  getActionsTotalCount$(): Observable<number> {
    return this.store.select(selectActionsTotalCount).pipe(
      filter(actionsTotalCount => !!actionsTotalCount)
    );
  }

  addAction(actionInsertDto: ActionInsertDto): Observable<void> {
    this.store.dispatch(fromActions.addAction({ payload: actionInsertDto }));

    return this.actions$.pipe(
      ofType(fromActions.addActionSuccess),
      first()
    ) as Observable<void>;
  }

  updateAction(actionUpdateDto: ActionUpdateDto): Observable<void> {
    this.store.dispatch(fromActions.updateAction({ payload: actionUpdateDto }));

    return this.actions$.pipe(
      ofType(fromActions.updateActionSuccess),
      first()
    ) as Observable<void>;
  }

  deleteActions(actionDeleteDto: ActionDeleteDto): Observable<void> {
    this.store.dispatch(fromActions.deleteActions({ payload: actionDeleteDto }));

    return this.actions$.pipe(
      ofType(fromActions.deleteActionsSuccess),
      first()
    ) as Observable<void>;
  }
}
