import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  Customer,
  CustomerDeleteDto,
  CustomerInsertDto, CustomerUpdateDto,
  Request
} from '@exe/client/client-web/core/supabase';
import { ApiResponsePaginated } from '../store.model';

const prefix = '[Customers]';

export const clearCustomers = createAction(`${prefix} Clear customers`);
export const fetchCustomers = createAction(`${prefix} Fetch customers`, props<{ payload: Request }>());
export const fetchCustomersSuccess = createAction(`${prefix} Fetch customers success`, props<{ payload: ApiResponsePaginated<Customer> }>());
export const fetchCustomersFailure = createAction(`${prefix} Fetch customers failure`, props<{ payload: HttpErrorResponse }>());

export const addCustomer = createAction(`${prefix} Add customer`, props<{ payload: CustomerInsertDto }>());
export const addCustomerSuccess = createAction(`${prefix} Add customer success`);
export const addCustomerFailure = createAction(`${prefix} Add customer failture`, props<{ payload: HttpErrorResponse }>());

export const updateCustomer = createAction(`${prefix} Update customer`, props<{ payload: CustomerUpdateDto }>());
export const updateCustomerSuccess = createAction(`${prefix} Update customer success`);
export const updateCustomerFailure = createAction(`${prefix} Update customer failture`, props<{ payload: HttpErrorResponse }>());

export const deleteCustomers = createAction(`${prefix} Delete customers`, props<{ payload: CustomerDeleteDto }>());
export const deleteCustomersSuccess = createAction(`${prefix} Delete customers success`);
export const deleteCustomersFailure = createAction(`${prefix} Delete customers failure`, props<{ payload: HttpErrorResponse }>());
