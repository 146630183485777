import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomersState } from './customers.reducers';

export const selectCustomersState = createFeatureSelector<CustomersState>('customers');

export const selectCustomers = createSelector(selectCustomersState, (state) => state.customers.data);
export const selectCustomersIsLoading = createSelector(selectCustomersState, (state) => state.customers.isLoading);
export const selectCustomersIsLoaded = createSelector(selectCustomersState, (state) => state.customers.isLoaded);
export const selectCustomersErrors = createSelector(selectCustomersState, (state) => state.customers.errors);
export const selectCustomersTotalCount = createSelector(selectCustomersState, (state) => state.customers.data?.totalCount);
